<template>
  <div>
    <ArticleDetail
      :slug="$route.params.slug"
    />
  </div>
</template>

<script>
import ArticleDetail from "@/components/news/ArticleDetail";

export default {
  name: 'NewsDetail',
  components: {
    ArticleDetail,
  },
}
</script>
